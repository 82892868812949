import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/michal/work/bite-api/frontend/dashboard/src/components/layout/dashboard.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Image Input Format`}</h1>
    <p>{`The API accepts new images in the body in the following ways`}</p>
    <ul>
      <li parentName="ul">{`ID if the image has been been posted previously`}</li>
      <li parentName="ul">{`JSON with a base64 encoded image or source_url`}</li>
      <li parentName="ul">{`Multipart form  and  the image in the file parameter`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`Image { 
    id ID of previously submitted image
    base64 Image encoded as Base64 with a JSON request
    file  Multi Part Form
    source_url A URL of an Image which is publicly accessible.
}`}</code></pre></div>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      